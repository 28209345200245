<template>
  <section class="settings">
    <template>
      <el-card
        :class="{ dark: false }"
        v-for="(item, index) in pageList"
        :key="index"
      >
        <h2 slot="header" class="clearfix">
          {{ item.category }}
        </h2>
        <ul class="settings-list">
          <li
            v-for="(route, index) in item.items"
            v-can="route.permission"
            @click="onHandler(index, route)"
            :key="index"
          >
            <router-link
              class="settings-list__item"
              :to="{
                path: `/settings-category/${route.link}`,
                query: { slug: item.slug },
              }"
            >
              <i class="el-icon-s-data icon"></i>
              <span>
                {{ $t(route.name) | truncate(20, "...") }}
              </span>
            </router-link>
          </li>
        </ul>
      </el-card>
    </template>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  data() {
    return {
      isActive: 0,
      isCurrentRouter: true,
      pageList: [
        {
          slug: "system-settings",
          category: i18n.t("message.system_settings"),
          items: [
            {
              name: "message.services",
              link: "services/index",
              permission: "services.index",
            },
            {
              name: "message.countries",
              link: "states/index",
              permission: "states.index",
            },
            {
              name: "message.region",
              link: "region/index",
              permission: "regions.index",
            },
            {
              name: "message.cities",
              link: "cities/index",
              permission: "cities.index",
            },
            {
              name: "message.condition",
              link: "conditions/index",
              permission: "conditions.index",
            },
            {
              name: "message.owner_clinic",
              link: "ownerClinic/index",
              permission: "ownerClinic.index",
            },
            {
              name: "message.order_column",
              link: "orderColumn/index",
              permission: "roles.index",
            },
            {
              name: "message.excelReport",
              link: "excelColumns/index",
              permission: "excelReports.3",
            },
          ],
        },
        {
          slug: "med-settings",
          category: i18n.t("message.doctors_settings"),
          items: [
            {
              name: "message.doctorSign",
              link: "doctorSign/index",
              permission: "doctorSign.index",
            },
            {
              name: "message.specialities",
              link: "specialties/index",
              permission: "specialties.index",
            },
            {
              name: "message.rooms",
              link: "rooms/index",
              permission: "rooms.index",
            },
            {
              name: "message.time",
              link: "hours/index",
              permission: "hours.index",
            },
            {
              name: "message.work_schedule",
              link: "workTimes/index",
              permission: "work_times.index",
            },
          ],
        },
        {
          category: i18n.t("message.partners"),
          slug: "partners",
          items: [
            {
              name: "message.partner_doctor",
              link: "partnerDoctor/index",
              permission: "partner_doctors.index",
            },
            {
              name: "message.partner_clinic",
              link: "partnerClinic/index",
              permission: "partner_doctors.index",
            },
          ],
        },
        {
          category: i18n.t("message.cashbox"),
          slug: "cassa",
          items: [
            {
              name: "message.currency",
              link: "currencies/index",
              permission: "currencies.index",
            },
            {
              name: "message.payment_type",
              link: "paymentTypes/index",
              permission: "payment_types.index",
            },
            {
              name: "message.flow_types",
              link: "costs/index",
              permission: "costs.index",
            },
          ],
        },
        {
          category: i18n.t("message.warehouse"),
          slug: "warehouse",
          items: [
            {
              name: "message.currency",
              link: "currenciesWarehouses/index",
              permission: "currencies.index",
            },
            {
              name: "message.groups",
              link: "groupsWarehouses/index",
              permission: "currencies.index",
            },
            {
              name: "message.measurements",
              link: "measurementsWarehouses/index",
              permission: "currencies.index",
            },
            {
              name: "message.status",
              link: "statuses/index",
              permission: "currencies.index",
            },
          ],
        },
        {
          category: i18n.t("message.conclusions"),
          slug: "conclusion",
          items: [
            {
              name: "message.category_mkb",
              link: "categoryFormTemplates/index",
              permission: "categoryFormTemplates.index",
            },
            {
              name: "message.forms_of_documents",
              link: "documentations/index",
              permission: "documentations.index",
            },
          ],
        },
        {
          category: i18n.t("message.actions"),
          slug: "actions",
          items: [
            {
              link: "users/index",
              name: "message.users",
              permission: "users.index",
            },
            {
              link: "roles/index",
              name: "message.roles",
              permission: "roles.index",
            },
            {
              link: "permissions/index",
              name: "message.access_rights",
              permission: "permissions.index",
            },
            {
              link: "printAudit",
              name: "message.print_audit",
              permission: "print_audit.index",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      // mode: "MODE"
    }),
  },
  methods: {
    onHandler(index, route) {
      this.isActive = index;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  gap: 18px;

  // settings-list
  &-list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;

    // settings-list__item
    &__item {
      width: 100%;
      height: 100%;
      padding: 1rem 12px;
      border-radius: 5px;
      border: 1px solid #d1d5db;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
      text-align: center;
      color: #92929f;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      span {
        font-size: 16px;
      }

      .icon {
        color: #92929f;
        font-size: 2rem;
        transition: color 0.2s ease-in-out;
      }

      &:hover {
        color: #60a5fa;
        background-color: rgba($color: #fff, $alpha: 0.4);
        border-color: #60a5fa;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .icon {
          color: #60a5fa;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.settings {
  .el-card {
    width: 100%;

    // el-card__header
    &__header {
      margin: 0;
      padding: 12px 20px;

      h2 {
        font-size: 1.2rem;
        font-weight: 600;
        color: #212121a4;
        margin: 0;
      }
    }

    &.dark {
      background-color: #151e34c2 !important;
      border-color: #44587c75 !important;

      h2 {
        color: #fff;
      }

      .el-card__header {
        border-color: #44587c75;
      }

      .settings-list__item {
        border-color: #44587c75;
        background-color: #2a2a3fa6;

        &:hover {
          color: #fff;
          border-color: #fff;

          .icon {
            color: #fff !important;
          }
        }
      }
    }
  }
}
</style>
